import React from "react";
import "../../style/header.scss";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faGithub } from "@fortawesome/free-brands-svg-icons";

function Header() {
  return (
    <nav className='header'>
      <img className='logo' src={logo} alt='logo benjamin' />
      <div className='links-header'>
        <Link to='/' className='header-links'>
          Accueil
        </Link>
        <Link to='/apropos' className='header-links'>
          À propos
        </Link>
        <Link to='projets' className='header-links'>
          Projets
        </Link>
        <Link to='contact' className='header-links'>
          Contact
        </Link>
      </div>
      <a
        href='https://github.com/BVN8?tab=repositories'
        target='_blank'
        rel='noopener noreferrer'
      >
        <FontAwesomeIcon
          icon={faGithub}
          style={{ color: "#eae7dd" }}
          className='logo-header'
        />
      </a>
      <a
        href='https://www.linkedin.com/in/benjamin-vincent-neveu-35b393253/'
        target='_blank'
        rel='noopener noreferrer'
      >
        <FontAwesomeIcon
          icon={faLinkedin}
          style={{ color: "#eae7dd" }}
          className='logo-header'
        />
      </a>
    </nav>
  );
}

export default Header;
