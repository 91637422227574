import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Header from "./components/Header/header";
import Home from "./pages/Home/home";
import About from "./pages/About/about";
import Projet from "./pages/Projets/projets";
import Contact from "./pages/Contact/contact";
import { createGlobalStyle } from "styled-components";
import Footer from "./components/Footer/footer";
import ProjetDetails from "./pages/ProjetDetails/projetdetail";
import ErrorPage from "./components/Error/error";

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Quicksand', sans-serif;
    margin: 0;
    padding: 0;
  }
`;

function App() {
  return (
    <Router>
      <GlobalStyle />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/apropos" element={<About />} />
        <Route path="/projets" element={<Projet />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route path="/projets/:projetsId" element={<ProjetDetails />} />
        <Route path="/projets/8" element={<Navigate to="/error" replace />} />
      </Routes>
      <Footer />
    </Router>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
