import React from "react";
import "../../style/downloadCV.scss";

const DownloadCV = () => {
  return (
    <div>
      <a
        href='/CV_Benjamin_Vincent-Neveu_dev_2024.pdf'
        download='CV_Benjamin_Vincent-Neveu_dev_2024.pdf'
      >
        <button className='button-link'>Mon CV</button>
      </a>
    </div>
  );
};

export default DownloadCV;
