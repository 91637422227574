import React from "react";
import { Link } from "react-router-dom";
import MenuResponsive from "../../components/MenuResponsive/menuresponsive.jsx";
import "../../style/home.scss";

function Home() {
  return (
    <div className='home'>
      <MenuResponsive />
      <h1>Bonjour, je suis Benjamin.</h1>
      <h3>
        Je suis <b>développeur front-end</b> <br /> basé à Lille, Nord.
      </h3>
      <div className='buttons'>
        <Link to='/projets'>Projets</Link>
        <Link to='/contact'>Contact</Link>
      </div>
    </div>
  );
}

export default Home;
